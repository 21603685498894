<template>
    <div>
        <div style="text-align:start;text-indent:0px">
            <div style="color:rgb(0,0,0);font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none"><font face="Helvetica" style="font-size:14px"><font style="color:rgb(34,34,34)"><br>Met vriendelijke groet,<br style="line-height:24px">Kind regards,</font><br style="line-height:24px"><br style="line-height:24px"><span style="color:rgb(82,90,109)"><span style="line-height:24px"><span style="line-height:24px;color:rgb(15,19,24)">{{ form.fullname }}<br></span></span></span><font color="#0f1318"><i>{{ form.jobtitle !== 'other' ? form.jobtitle : form.other_function }}</i></font></font></div>
            <div style="color:rgb(0,0,0);font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none"><font face="Helvetica" style="font-size:14px"><font color="#0f1318"><i><br></i></font></font></div>
            <div v-if="form.workdays"><font face="Helvetica" color="#00b9ff"><span style="font-size:14px"><i>Werkdagen: {{ form.workdays }}</i></span></font></div>
            <div style="color:rgb(0,0,0);font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none"><font face="Helvetica" style="font-size:14px"><font color="#0f1318"><i><br></i></font></font></div>
            <div style="color:rgb(0,0,0);font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none"><font face="Helvetica" style="font-size:14px"><font color="#0f1318"><i><br></i></font></font></div>
            <div style="color:rgb(0,0,0);font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none"><br></div>
        </div>
        <span><img src="https://cdn.triggerfish.nl/triggerfish_nl/signature/logo_doop.png"></span>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0)"><span style="font-size:14px;font-family:Helvetica, sans-serif, Arial;color:rgb(46,6,116)"><br></span></div>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0)"><span style="font-size:14px;font-family:Helvetica, sans-serif, Arial;color:rgb(46,6,116)"><br></span></div>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0)"><span style="font-size:14px;font-family:Helvetica, sans-serif, Arial;color:rgb(46,6,116)"><br></span></div>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0)"><span style="font-size:14px;font-family:Helvetica, sans-serif, Arial;color:#000000">Roomweg 63</span><span style="font-size:14px;font-family:Helvetica, sans-serif, Arial;color:rgb(46,6,116)">&nbsp;</span></div>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:#000000;">
            <div><font color="#000000" face="Helvetica"><font color="#000000" face="Helvetica"><span style="font-size:14px">7523 BL Enschede</span></font><br><font color="#000000" face="Helvetica"><span style="font-size:14px">The Netherlands&nbsp;</span></font><br>
            <span style="font-size:14px">+31 (0)85 760 50 85</span><br />
            <span style="font-size:14px" v-if="form.mobile">M: {{ form.mobile }}</span>
        </font></div>
        </div>
        <div style="font-family:Helvetica, sans-serif, Arial;font-size:12px;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0)">
            <div><font color="#00b9ff" face="Helvetica"><span style="font-size:14px"><a href="https://wearedoop.com" target="_blank">https://wearedoop.com</a></span></font></div>
            <div><br></div>
            <div><font face="Helvetica" style="font-size:14px"><br></font></div>
            <div><br></div>
            <div><span style="font-size:14px"><font face="Helvetica" color="#3f079a">strategy&nbsp;&nbsp;&nbsp;creativity&nbsp;&nbsp;&nbsp;design&nbsp;&nbsp;&nbsp;technology&nbsp;&nbsp;&nbsp;services&nbsp;&nbsp;&nbsp;intelligence</font></span></div>
            <div><span style="color:rgb(0,185,255);font-size:14px"><font face="Helvetica"><br></font></span></div>
            <div><span style="color:rgb(146,146,146);font-size:14px"><font face="Helvetica"><br></font></span></div>
            <div><font face="Helvetica"><span style="color:rgb(146,146,146);font-size:14px"><br></span></font></div>
            <div style="font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;margin:0px;font-stretch:normal;font-size:14px;line-height:normal;font-family:&quot;Helvetica Neue&quot;;color:rgb(128,128,128)"><span>De informatie verzonden in dit e-mail bericht is uitsluitend bestemd voor de geadresseerde. Gebruik van deze informatie door anderen dan de geadresseerde is niet toegestaan. Indien u dit bericht ten onrechte ontvangt, wordt u verzocht de inhoud niet te gebruiken maar de afzender direct te informeren door het bericht te retourneren en het daarna te verwijderen.&nbsp;Op al onze opdrachten zijn onze algemene voorwaarden van toepassing. Deze kunnen&nbsp;<a href="https://storage.googleapis.com/doop/downloads/NLdigital-Voorwaarden-NL.pdf" target="_blank"><span style="font-stretch:normal;line-height:normal;font-family:Helvetica, sans-serif, Arial;color:rgb(21,169,255)">hier</span></a>&nbsp;worden ingezien en gedownload.&nbsp;</span></div>
        </div>
        <div class="yj6qo"></div>
        <div class="adL"><span style="font-family:Helvetica, sans-serif, Arial;font-style:normal;font-variant-caps:normal;font-weight:normal;letter-spacing:normal;text-align:start;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;text-decoration:none;color:rgb(0,0,0);font-size:14px"><br><br></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>
