<template>
    <div id="app">
        <form class="form" v-if="done === false" @submit.prevent>
            <h1>Emailhandtekening generator</h1>
            <ol>
                <li>Vul al je gegevens in</li>
                <li>Druk op aanmaken</li>
                <li>Selecteer alles op de volgende pagina (command + a, command + c) en plak deze in het emailprogramma onder hantekening.</li>
            </ol>

            <div class="field">
                <label>Volledige naam</label>
                <input v-model="form.fullname" />
            </div>

            <div class="field">
                <label>Functie</label>
                <select v-model="form.jobtitle">
                    <option :value="null">Kies functie</option>
                    <option value="Backend Developer">Backend Developer</option>
                    <option value="Frontend Developer">Frontend Developer</option>
                    <option value="Online Marketeer">Online Marketeer</option>
                    <option value="Digital Designer">Digital Designer</option>
                    <option value="Online Activation Manager">Online Activation Manager</option>
                    <option value="Strateeg">Strateeg</option>
                    <option value="other">Anders..</option>
                </select>
            </div>

            <div class="field" v-if="form.jobtitle === 'other'">
                <label>Andere functie</label>
                <input v-model="form.other_function" />
            </div>

            <div class="field">
                <label>Mobiel (optioneel) (+31 (0)6 00 00 00 00)</label>
                <input v-model="form.mobile" placeholder="+31 (0)6 00 00 00 00" />
            </div>

            <div class="field">
                <label>Werkdagen (indien niet fulltime)</label>
                <input v-model="form.workdays" placeholder="ma, di, do, vr" />
            </div>

            <div class="field">
                <label>Vestiging</label>
                <select v-model="form.location">
                    <option value="Zwolle">Zwolle</option>
                    <option value="Enschede">Enschede</option>
                </select>
            </div>

            <button @click="finishForm">
                Aanmaken
            </button>
        </form>

        <div class="signature" v-else>
            <Triggerfish :form="form" v-if="form.location === 'Zwolle'" />
            <Doop :form="form" v-else-if="form.location === 'Enschede'" />
        </div>
    </div>
</template>

<script>
import Triggerfish from './components/signatures/Triggerfish.vue';
import Doop from './components/signatures/Doop.vue';

export default {
    components: {
        Triggerfish,
        Doop,
    },
    data() {
        return {
            done: false,
            form: {
                fullname: null,
                jobtitle: null,
                mobile: null,
                workdays: null,
                other_function: null,
                location: 'Zwolle',
            },
        };
    },
    methods: {
        finishForm() {
            this.done = true;
            this.$nextTick(() => {
                alert('Kopieer alle tekst en plaats deze in je email client.');
            });
        },
    },
};
</script>
